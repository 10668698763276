import React from "react";

import "../styles/ReceitaPage.css";

import { Link } from "react-router-dom";

import { GiWheat, GiSpoon } from "react-icons/gi";
import { BsClock, BsFillPeopleFill, BsBarChart } from "react-icons/bs";
import Footer from "../layouts/Footer";

import RedesSociais from "../layouts/RedesSociais";
import withRouter from "../../withRouter";

import { db, storage } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import ReactGA from 'react-ga';

class ReceitaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "http://localhost:3000/",
      Receita: [],
      error: false,
      UrlImage: '',
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    document.getElementById("root").scrollTop = 0;

    const getReceita = async () => {
      try {
        const docRef = doc(db, "receitas", this.props.params.id);

        const docSnap = await getDoc(docRef);


        if (docSnap.exists()) {
          
          await this.setState({Receita: [docSnap.data()]})

          const spaceRef = ref(storage, `receitasImages/${docSnap.data().image}`);


          const url = await getDownloadURL(spaceRef);

          await this.setState({UrlImage: url})



        } else {
          console.log("error");
        }





      } catch (error) {
        console.log(error)
      }
    };

    getReceita();
  }


  HandleWhatsapp = () => {
    var text = this.state.Receita[0]?.titulo;
    var url = encodeURIComponent(window.location.href);

    var whatsappShareUrl =
      "https://api.whatsapp.com/send?text=" + text + "%20" + url;

    return whatsappShareUrl
  };



  render() {
    if (this.state.error) {
      alert("ERROR 404 URL incon");
    }

    return (
      <div className="Container-ReceitaUnica">
        <div className="BannerReceitaUnica">
          <div className="Content-BannerReceitaUnica">
            <div className="Header">
              <div className="Logo-header">
                <p>
                  Tatá Alimentos<span className="vermelho">.</span>
                </p>
              </div>

              <div className="Menu-header">
                <ul className="Menu">
                  <li>
                    <Link className="amarelo effectedY" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="effected branco" to="/receitas">
                      Receitas
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="effected branco" to="/produtos">
                      Produtos
                    </Link>
                  </li> */}
                  <li>
                    <Link className="effected branco" to="/contato">
                      Contato
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="Title-Banner">
              <p>{this.state.Receita[0]?.titulo}</p>
            </div>
          </div>
        </div>

        <div className="Content-Informacoes">
          <div className="ImageContainer-Informacoes">
            <div
              className="ImageInfo"
              style={
                this.state.Receita[0]?.image
                  ? {
                      backgroundImage: `url(${this.state.UrlImage})`,
                    }
                  : null
              }
            ></div>
          </div>

          <div className="contentContainer-Informacoes">
            <div className="TimeItens-infor">
              <div className="ItemTime">
                <span>
                  <BsClock />
                </span>
                {this.state.Receita[0]?.tempo} Minutos
              </div>

              <div className="ItemTime">
                <span>
                  <BsFillPeopleFill />
                </span>
                {this.state.Receita[0]?.porcao === '1' ? (`${this.state.Receita[0]?.porcao} Porção`) : (`${this.state.Receita[0]?.porcao} Porções`)} 
              </div>

              <div className="ItemTime">
                <span>
                  <BsBarChart />
                </span>
                {this.state.Receita[0]?.dificuldade}
              </div>
            </div>

            <RedesSociais unic link={window.location.href} WhatsappFunc={this.HandleWhatsapp} />

            <div className="text-infor">{this.state.Receita[0]?.descricao}</div>
          </div>
        </div>

        <div className="Content-ReceitaUnica">
          <div className="Content-Ingredientes">
            <p className="IngredientesTitle">
              <span>
                <GiWheat />{" "}
              </span>
              Ingredientes
            </p>

            <ul className="IngretientesReceita">
              {
                this.state.Receita.length ? this.state.Receita[0].ingredientes.map(item=>{
                  return <li key={item}>{item}</li>;
                }) : null
              }
            </ul>
          </div>

          <div className="Content-ModoPreparo">
            <p className="IngredientesTitle">
              <span>
                <GiSpoon />{" "}
              </span>
              Modo de Preparo
            </p>

            <ul className="IngretientesReceita">
            {
                this.state.Receita.length ? this.state.Receita[0].modoPreparo.map(item=>{
                  return <li key={item}>{item}</li>;
                }) : null
              }
            </ul>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(ReceitaPage);
