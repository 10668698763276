import React from "react";

import "./ItemReceita.css";
import Proptypes from "prop-types";
import { useNavigate } from "react-router-dom";


import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

class ItemReceita extends React.Component {
  constructor() {
    super();
    this.state = {
        UrlImage: '',
    };
  }

  static propTypes = {
    titulo: Proptypes.string,
    image: Proptypes.string,
  };

  componentDidMount() {
    const GetImage = async () => {
      const spaceRef = ref(storage, `receitasImages/${this.props.image}`);

      const url = await getDownloadURL(spaceRef);

      await this.setState({ UrlImage: url });
    };

    GetImage();
  }

  render() {
    return (
      <div
        onClick={() => {
          this.props.navigation(`/receita/${this.props.id}`);
        }}
        className="Container-Item-Receita"
      >
        <div
          className="image-receita"
          style={{ backgroundImage: `url(${this.state.UrlImage})` }}
        ></div>

        <div className="text-receita">
          <p>{this.props.titulo}</p>
        </div>
      </div>
    );
  }
}

const PFunc = (props) => {
  const navigation = useNavigate();

  return <ItemReceita {...props} navigation={navigation} />;
};

export default PFunc;
