import React from "react";

import "./Banner.css";

import Selos from "./Selos";
import { Link } from "react-router-dom";

class Banner extends React.Component {
  render() {
    return (
      <div className="Banner-container">
        <div className="Content-Banner">
          <div className="Header">
            <div className="Logo-header">
              <p>
                Tatá Alimentos<span className="vermelho">.</span>
              </p>
            </div>

            <div className="Menu-header">
              <ul className="Menu">
                <li>
                  <Link className="amarelo effectedY" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="effected branco" to="/receitas">
                    Receitas
                  </Link>
                </li>
                {/* <li>
                  <Link className="effected branco" to="/produtos">
                    Produtos
                  </Link>
                </li> */}
                <li>
                  <Link className="effected branco" to="/contato">
                    Contato
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="Title-Banner">
            <p>O legítimo sabor</p>
          </div>

          <Selos />
        </div>
      </div>
    );
  }
}

export default Banner;
