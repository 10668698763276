import React from "react";

import "../styles/Contato.css";
import Container from "../layouts/Container";
import { Link } from "react-router-dom";
import RedesSociais from "../layouts/RedesSociais";
import Footer from "../layouts/Footer";

import { MdLocationPin } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import ReactGA from 'react-ga';

class Contato extends React.Component {



  componentDidMount(){
    document.getElementById('root').scrollTop = 0;

    ReactGA.pageview(window.location.pathname);
  }


  render() {
    return (
      <Container all collumn>
        <div className="BannerContato">
          <div className="ContentBanner">
            <div className="Header">
              <div className="Logo-header">
                <p>
                  Tatá Alimentos<span className="vermelho">.</span>
                </p>
              </div>

              <div className="Menu-header">
                <ul className="Menu">
                  <li>
                    <Link className="amarelo effectedY" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="effected branco" to="/receitas">
                      Receitas
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="effected branco" to="/produtos">
                      Produtos
                    </Link>
                  </li> */}
                  <li>
                    <Link className="effected branco" to="/contato">
                      Contato
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="Title-Banner">
              <p>Contatos</p>
            </div>
          </div>
        </div>

        <div className="ContainerContatoInfor">
          <div className="MapResponsive">
            <iframe
              title="Mapa"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d510.5005643834443!2d-43.18602478329292!3d-21.26729330579005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa257695c54703f%3A0xfa10c7729cbc5e3b!2sTata%20Alimentos!5e0!3m2!1spt-BR!2sbr!4v1679149303336!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="InforContent">
            <RedesSociais />

            <hr />

            <div className="InformationContatEspecifc ">
              <span>
                <MdLocationPin />
              </span>
              Rua Leônidas Marota, 173 - CEP: 36180-000 | Jardim América - Rio
              Pomba/MG
            </div>

            <div className="InformationContatEspecifc ">
              <span>
                <BsFillTelephoneFill />
              </span>
              (32) 3571-1990
            </div>

            <div className="InformationContatEspecifc ">
              <span>
                <IoLogoWhatsapp />
              </span>
              (32) 9 9926-1990
            </div>
          </div>
        </div>

        <Footer />
      </Container>
    );
  }
}

export default Contato;
