import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/pages/Home";
import ReceitaPage from "./components/pages/ReceitaPage";
import Receitas from "./components/pages/Receitas";

// import Login from './components/pages/Login';
// import Crud from './components/pages/Crud';
// import EbookPage from './components/pages/EbookPage';
import Contato from "./components/pages/Contato";
// import Produtos from "./components/pages/Produtos";
// import ProdutoPage from "./components/pages/ProdutoPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/receitas" element={<Receitas />} />
        <Route path="/receita/:id" element={<ReceitaPage />} />
        {/* <Route path="/produto/:id" element={<ProdutoPage />} /> */}
        <Route path="/contato" element={<Contato />} />
        {/* <Route path="/produtos" element={<Produtos />} /> */}
        
        {/* AQUI EU DEVO TIRAR OS COMENTÁRIOS QUANDO FOR LANÇAR A ATUALIZAÇÃO */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
