import React from "react";

import "./Selos.css";
class Selos extends React.Component {
  render() {
    return (
      <div className="Selos-Banner">
        <div className="Item-selos">
          <div className="icon">Visão</div>
          <div className="Content-Selos">
            Proporcionar sabores extraordinários à todas as pessoas que saboream nossos alimentos!
          </div>
        </div>

        <div className="Item-selos">
          <div className="icon">Missão</div>
          <div className="Content-Selos">
          Produzir alimentos de qualidade insuperável, proporcionando uma
            experiência única!
          </div>
        </div>

        <div className="Item-selos">
          <div className="icon">Valor</div>
          <div className="Content-Selos">
            Ética, respeito ao meio ambiente, respeito à comunidade e respeito ao
            ser humano.
          </div>
        </div>
      </div>
    );
  }
}

export default Selos;
