import React from "react";

import "./ReceitasHooks.css";

import ItemReceita from "./ItemReceita";

import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

class ReceitasHooks extends React.Component {
  constructor() {
    super();
    this.state = {
      Receitas: [],
    };
  }

  componentDidMount() {
    const GetReceitas = async () => {
      try {
        const q = query(collection(db, "receitas"));

        const querySnapshot = await getDocs(q);

        let array = [];
        querySnapshot.forEach(async (doc) => {
          array = [...array, { id: doc.id, itemReceita: doc.data() }];
        });

        await this.setState({ Receitas: array });
      } catch (error) {
        console.log(error);
      }
    };

    GetReceitas();
  }

  render() {
    return (
      <div className="Box-receita">
        <p className="Receitas-Title">Novas Receitas</p>
        <div className="Container-Receitas">
          {this.state.Receitas.map((item) => {
            return (
              <ItemReceita
                id={item.id}
                key={item.id}
                titulo={item.itemReceita.titulo}
                image={item.itemReceita.image}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ReceitasHooks;
