import React from "react";
import PropTypes from "prop-types"


import "./Container.css"

class Container extends React.Component{

    static propTypes = {
        center: PropTypes.bool,
        collumn: PropTypes.bool,
        all: PropTypes.bool
    }


    render(){

        const classes = [
            "container",
            this.props.center ? "center" : '',
            this.props.collumn ? "collumn" : '',
            this.props.all ? "all" : '',
        ]



        return(<div className={classes.join(' ').trim()}>
            {this.props.children}
        </div>)
    }
}




export default Container;