import React, { useEffect } from "react";

import "../styles/Home.css";

import Banner from "../layouts/Banner";
import Container from "../layouts/Container";
import RedesSociais from "../layouts/RedesSociais";
import SobreNos from "../layouts/SobreNos";
// import Carrossel from "../layouts/Carrossel";
import ReceitasHooks from "../layouts/ReceitasHooks";
import Footer from "../layouts/Footer";

import Equipe from "../img/Equipe.jpg";
import Historia from "../img/Historia.jpg";

import ReactGA from 'react-ga';

const Home = () => {

  ReactGA.initialize("G-G5MDVVQHL2")


  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  },[])


  return (
    <Container all collumn>
      <Banner />
      <RedesSociais />

      <SobreNos
        Title="Nossa história"
        Image={Historia}
        PrimeiroParagrafo={
          <p>
            Somos a Tatá Alimentos, uma indústria especializada em produtos
            cárneos de alto padrão. Nossa indústria foi fundada em
            <span className="vermelho"> 1989</span> pelo Sr. Gilson e Sra. Meire.
            Desde então, temos crescido e evoluído para nos tornarmos uma
            referência nos mercados, distribuidoras e restaurantes.
          </p>
        }
        SegundoParagrafo={
          <p>
            A nossa empresa é uma referência em produtos cárneos, oferecendo
            qualidade e contando com uma equipe comprometida e capacitada para
            atender as necessidades de nossos clientes. Temos uma equipe
            especializada em receitas que foram desenvolvidas e aprimoradas pela
            nossa indústria ao longo de mais de 34 anos de história.
          </p>
        }
      />

      <SobreNos
        Title="Valores"
        Image={Equipe}
        PrimeiroParagrafo={
          <p>
            Ao longo dos anos, nossa empresa enfrentou desafios e superou
            obstáculos, mas nunca perdeu de vista seus valores e compromisso com
            clientes e colaboradores. Podemos dizer com orgulho que nossa
            história é marcada pela inovação, qualidade e excelência em tudo o
            que fazemos.
          </p>
        }
        invert
      />

      {/* <Carrossel /> */}
      <ReceitasHooks />
      <Footer />
    </Container>
  );
};

export default Home;
