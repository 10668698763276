import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyAn-vuQLQ6FM99px-LUFT5neH8np71zicQ",
  authDomain: "tataalimentos-c41ec.firebaseapp.com",
  projectId: "tataalimentos-c41ec",
  storageBucket: "tataalimentos-c41ec.appspot.com",
  messagingSenderId: "82500923346",
  appId: "1:82500923346:web:ae8ebd07a3a0bc2e22620b"
};



const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// eslint-disable-next-line
const analytics = getAnalytics(app);

export {db, storage};
