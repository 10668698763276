import React from "react";

import PropTypes from "prop-types";

import "./RedesSociais.css";
import { FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";

import { GrMail } from "react-icons/gr";
import { BsFillTelephoneFill } from "react-icons/bs";

import { BiShareAlt } from "react-icons/bi";
import { BsLinkedin } from "react-icons/bs";

class RedesSociais extends React.Component {
  static propTypes = {
    unic: PropTypes.bool,
    link: PropTypes.string,
    footer: PropTypes.bool,
  };

  HandleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: this.state.NamePost,
        url: "https://www.example.com",
      });
    }
  };

  HandleLinkedIn = () => {
    var url = encodeURIComponent(window.location.href);

    var linkedInShareUrl =
      "https://www.linkedin.com/sharing/share-offsite/?url=" + url;

    window.open(linkedInShareUrl, "_blank");
  };

  HandleFacebook = () => {
    var url = encodeURIComponent(window.location.href);

    var FacebookShareUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" + url;

    window.open(FacebookShareUrl, "_blank");
  };

  HandleWhatsapp = () => {
    var text = 'Venha conhecer nossos produtos!'; // Texto que será compartilhado no WhatsApp
    var url = encodeURIComponent(window.location.href); // Obtém a URL atual e codifica para uso no parâmetro da URL

    var whatsappShareUrl =
      "https://api.whatsapp.com/send?text=" + text + "%20" + url;

    window.open(whatsappShareUrl, "_blank");
  };

  render() {
    if (this.props.unic) {
      return (
        <ul className="Rede-Container">
            <li onClick={this.HandleFacebook}>
              <FaFacebookF />
            </li>
          <li onClick={this.HandleWhatsapp}>
              <FaWhatsapp />
          </li>
          <li onClick={this.HandleLinkedIn}>
            <BsLinkedin />
          </li>
          <li onClick={this.HandleShare}>
            <BiShareAlt />
          </li>
        </ul>
      );
    }

    if (this.props.footer) {
      return (
        <ul className="Rede-Container2">
          <a
            href="https://www.facebook.com/tataalimentosrp/"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <FaFacebookF />
            </li>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=32999261990"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <FaWhatsapp />
            </li>
          </a>
          <a
            href="https://www.instagram.com/tataalimentos/"
            target="_blank"
            rel="noreferrer"
          >
            <li>
              <FaInstagram />
            </li>
          </a>
          <a href="mailto:tataalimentos@tataalimentos.com.br">
            <li>
              <GrMail />
            </li>
          </a>
          <a href="tel:35711990">
            <li>
              <BsFillTelephoneFill />
            </li>
          </a>
        </ul>
      );
    }

    return (
      <ul className="Rede-Container">
        <a
          href="https://www.facebook.com/tataalimentosrp/"
          target="_blank"
          rel="noreferrer"
        >
          <li>
            <FaFacebookF />
          </li>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=32999261990"
          target="_blank"
          rel="noreferrer"
        >
          <li>
            <FaWhatsapp />
          </li>
        </a>
        <a
          href="https://www.instagram.com/tataalimentos/"
          target="_blank"
          rel="noreferrer"
        >
          <li>
            <FaInstagram />
          </li>
        </a>
        <a href="mailto:tataalimentos@tataalimentos.com.br">
          <li>
            <GrMail />
          </li>
        </a>
        <a href="tel:35711990">
          <li>
            <BsFillTelephoneFill />
          </li>
        </a>
      </ul>
    );
  }
}

export default RedesSociais;
