import React from "react";

import "./CarrosselReceitas.css";

import { GoArrowLeft, GoArrowRight } from "react-icons/go";

import { useNavigate } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { collection, query, getDocs, limit } from "firebase/firestore";
import { db, storage } from "../../firebase";

class CarrosselReceitas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: 0,
      Response: 0,
      Receitas: [],
    };
  }



  componentDidMount() {
    const GetReceitas = async () => {
      try {
        const q = query(collection(db, "receitas"), limit(12));

        const querySnapshot = await getDocs(q);

        const Array = [];

        querySnapshot.forEach(async (doc) => {

            const DownloadUrlImage = await getDownloadURL(ref(storage,`receitasImages/${doc.data().image}`));


          await Array.push({ id: doc.id, DownloadUrlImage: DownloadUrlImage, itemReceita: doc.data() })
          this.setState({ Receitas: Array });

        });

        

        

      } catch (error) {
        console.log(error);
      }
    };

    GetReceitas();
  }

  

  render() {
    window.addEventListener("resize", () => {
      const Id = document.getElementById("carrossel");

      try {
        if (document.documentElement.clientWidth > 1100) {
          Id.style.transform = `translateX(0)`;
        } else if (
          document.documentElement.clientWidth <= 1100 &&
          document.documentElement.clientWidth > 850
        ) {
          Id.style.transform = `translateX(0)`;
        } else if (
          document.documentElement.clientWidth <= 850 &&
          document.documentElement.clientWidth > 570
        ) {
          Id.style.transform = `translateX(0)`;
        } else if (document.documentElement.clientWidth < 570) {
          Id.style.transform = `translateX(0)`;
        }
      } catch (error) {}
    });

    const ProximaReceita = async () => {
      const Id = document.getElementById("carrossel");
      const Itens = document.querySelectorAll(
        "#carrossel .Item-slidecarrosselReceitas"
      );

      let List = [100, 50, 33, 25];

      await this.setState({ idx: this.state.idx + 1 });

      if (document.documentElement.clientWidth > 1100) {
        await this.setState({ Response: 3 });
      }
      if (
        document.documentElement.clientWidth <= 1100 &&
        document.documentElement.clientWidth > 850
      ) {
        await this.setState({ Response: 2 });
      }
      if (
        document.documentElement.clientWidth <= 850 &&
        document.documentElement.clientWidth > 570
      ) {
        await this.setState({ Response: 1 });
      }
      if (document.documentElement.clientWidth < 570) {
        await this.setState({ Response: 0 });
      }

      if (this.state.idx >= Itens.length - this.state.Response) {
        await this.setState({ idx: 0 });
      }

      Id.style.transform = `translateX(-${
        this.state.idx * List[this.state.Response]
      }%)`;
    };

    const AnteriorReceita = async () => {
      const Id = document.getElementById("carrossel");
      const Itens = document.querySelectorAll(
        "#carrossel .Item-slidecarrosselReceitas"
      );

      let List = [100, 50, 33, 25];

      await this.setState({ idx: this.state.idx - 1 });

      if (document.documentElement.clientWidth > 1100) {
        await this.setState({ Response: 3 });
      }
      if (
        document.documentElement.clientWidth <= 1100 &&
        document.documentElement.clientWidth > 850
      ) {
        await this.setState({ Response: 2 });
      }
      if (
        document.documentElement.clientWidth <= 850 &&
        document.documentElement.clientWidth > 570
      ) {
        await this.setState({ Response: 1 });
      }
      if (document.documentElement.clientWidth < 570) {
        await this.setState({ Response: 0 });
      }

      if (this.state.idx < 0) {
        await this.setState({ idx: Itens.length - this.state.Response - 1 });
      }

      Id.style.transform = `translateX(-${
        this.state.idx * List[this.state.Response]
      }%)`;
    };

    

    return (
      <div className="container-carrosselReceitas">
        <p className="CarrosselReceitas-title">Mais Acessádas</p>

        <div className="Container-GeralCarrosselReceitas">
          <div className="bot">
            <button
              onClick={() => {
                AnteriorReceita();
              }}
            >
              <GoArrowLeft />
            </button>
          </div>

          <div className="Static-carrosselReceitas">
            <div className="Slide-carrosselReceitas" id="carrossel">
              {this.state.Receitas.map((item) => {

                return (
                  <div
                    className="Item-slidecarrosselReceitas"
                    key={item.id}
                    onClick={() => {
                      this.props.navigation(`/receita/${item.id}`);
                    }}
                  >
                    <div
                      className="Image-slideCarrosselReceitas"
                      style={{
                        backgroundImage: `url(${item.DownloadUrlImage})`,
                      }}
                    ></div>

                    <div className="Title-CarrosselReceitas">
                      {item.itemReceita.titulo}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="bot">
            <button
              onClick={() => {
                ProximaReceita();
              }}
            >
              <GoArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const PFunc = (props) => {
  const navigation = useNavigate();

  return <CarrosselReceitas {...props} navigation={navigation} />;
};

export default PFunc;
