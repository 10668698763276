import React from "react";

import "./BannerReceitas.css";
import { Link, useNavigate } from "react-router-dom";
import { BiSearchAlt2 } from "react-icons/bi";
import { ref, getDownloadURL } from "firebase/storage";
import { collection, query, getDocs } from "firebase/firestore";
import { db, storage } from "../../firebase";

class BannerReceitas extends React.Component {
  constructor() {
    super();
    this.state = {
      buscaContent: [],
      Receitas: [],
      AvisoSemReceita: false,
    };
  }

  LimparBusca = async () => {
    if (this.state.BuscaValue === "" || this.state.BuscaValue === undefined) {
      await this.setState({ buscaContent: [] });
    }
  };

  Buscar = async (value) => {
    if (value === "" || value === undefined) {
      await this.setState({ buscaContent: [] });
      await this.setState({ AvisoSemReceita: false });
      return;
    }

    await this.setState({ buscaContent: [] });
    this.state.Receitas.forEach(async item =>{
      if(item.itemReceita.titulo.toLowerCase().includes(value.toLowerCase())){
        
        if(!this.state.buscaContent.includes(item)){
          await this.setState({ buscaContent: [...this.state.buscaContent,item] });
        }
        
      }
    })
    
  };


  componentDidMount(){
    
    const GetReceitas = async () => {
      try {
        const q = query(collection(db, "receitas"));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {

            const DownloadUrlImage = await getDownloadURL(ref(storage,`receitasImages/${doc.data().image}`));

          await this.setState({
            Receitas: [
              ...this.state.Receitas,
              { id: doc.id, DownloadUrlImage: DownloadUrlImage, itemReceita: doc.data() },
            ],
          });
        });
      } catch (error) {
        console.log(error);
      }
    };

    GetReceitas();
    
  }












  render() {
    return (
      <div className="Container-BannerReceitas">
        <div className="BackContrast">
          <div className="Header">
            <div className="Logo-header">
              <p>
                Tatá Alimentos<span className="vermelho">.</span>
              </p>
            </div>

            <div className="Menu-header">
              <ul className="Menu">
                <li>
                  <Link className="amarelo effectedY" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="effected branco" to="/receitas">
                    Receitas
                  </Link>
                </li>
                {/* <li>
                  <Link className="effected branco" to="/produtos">
                    Produtos
                  </Link>
                </li> */}
                <li>
                  <Link className="effected branco" to="/contato">
                    Contato
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>

          <div className="Title-Banner">
            <p>Busque sua Receita Favorita</p>
          </div>

          <div className="Form-BannerReceitas-buscar">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                type="text"
                placeholder="Pesquise Aqui"
                onChange={(e) => {
                  this.Buscar(e.target.value);
                }}
              />
              <button>
                <BiSearchAlt2 />
              </button>

              <div className="Form-BannerReceitas-resultado">
                {this.state.AvisoSemReceita ? (
                  <div className="Error-BannerReceitas-resultado">
                    Busca Desativada!<span className="vermelho">...</span>
                  </div>
                ) : (
                  this.state.buscaContent.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="Item-BannerReceitas-resultado"
                        onClick={() => {
                          this.props.navigation(`/receita/${item.id}`);
                        }}
                      >
                        <div
                          className="Item-BannerReceitas-resultado-foto"
                          style={{
                            backgroundImage: `url(${item.DownloadUrlImage})`,
                          }}
                        ></div>

                        <div className="Item-BannerReceitas-resultado-nome">
                          {item.itemReceita.titulo}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const PFunc = (props) => {
  const navigation = useNavigate();

  return <BannerReceitas {...props} navigation={navigation} />;
};

export default PFunc;
