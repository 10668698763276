import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

import RedesSociais from "./RedesSociais";

class Footer extends React.Component {
  render() {
    return (
      <footer className="Footer">
        <div className="Col">
          <div className="LogoFooter">
            <p>
              Tatá Alimentos<span className="vermelho">.</span>
            </p>
          </div>

          <div className="Page">
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <Link to="/receitas">Receitas</Link>
            </p>
            <p>
              <Link to="/contato">Contato</Link>
            </p>
          </div>

          <div className="Page">
            <p>
              <a
                href="mailto:euler.coutob@gmail.com?subject=Suporte Tatá Alimentos (Não Mude o Assunto)&body=Escreva Aqui o assunto do contato ao suporte"
                target="_blank"
                rel="noreferrer"
              >
                Suporte
              </a>
            </p>
            <p>
              <a href="mailto:euler.coutob@gmail.com" target="_blank" rel="noreferrer">
                Desenvolvedor
              </a>
            </p>
          </div>
        </div>

        <hr id="footerHR" />

        <div className="Col">
          <RedesSociais footer />
        </div>
        <div className="Col text">
          <p>©Copyright. Todos os direitos reservados e marca registrada | 2023 Tatá Alimentos</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
