import React from "react";

import "./SobreNos.css";

class SobreNos extends React.Component {
  render() {
    const classesContainer = [
      "Sobre-Container",
      this.props.invert ? "invert" : "",
    ];

    const classesTitle = [
      "Sobre-title",
      this.props.invert ? "textStart" : "textEnd",
    ];

    const classesText = [
      "Sobre-text",
      this.props.invert ? "textStart" : "textEnd",
    ];

    return (
      <div className={classesContainer.join(" ").trim()}>
        <div className="Sobre-content">
          <p className={classesTitle.join(" ").trim()}>
            {this.props.Title}
            <span className="vermelho">...</span>
          </p>
          <div className={classesText.join(" ").trim()}>
            {this.props.PrimeiroParagrafo}

            {this.props.SegundoParagrafo}
          </div>
        </div>
        <div className="Sobre-image">
          <img className="Image" src={this.props.Image} alt="Imagem referente ao grupo da tatá alimentos!" />
        </div>
      </div>
    );
  }
}

export default SobreNos;
