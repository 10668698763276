import React, { useEffect } from "react";

import "../styles/Receitas.css";

import Container from "../layouts/Container";
import BannerReceitas from "../layouts/BannerReceitas";
import RedesSociais from "../layouts/RedesSociais";
import CarrosselReceitas from "../layouts/CarrosselReceitas";
import ReceitasHooks from "../layouts/ReceitasHooks";
import Footer from "../layouts/Footer";
import ReactGA from 'react-ga';
const Receitas = () => {
  useEffect(() => {
    document.getElementById("root").scrollTop = 0;
    ReactGA.pageview(window.location.pathname);

  }, []);

  return (
    <Container all collumn>
      <BannerReceitas />
      <RedesSociais />
      <CarrosselReceitas />

      <ReceitasHooks />
      <Footer />
    </Container>
  );
};

export default Receitas;
